import * as React from "react";
import { Page } from "../components/styles";
import loadable from "@loadable/component";
import styled from "styled-components";
const Garden = loadable(() => import("../components/garden"));

const ErrorMessage = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  text-align: center;

  h1 {
    line-height: 3 !important;
  }
`;

const NotFoundPage = () => {
  return (
    <Page>
      <Garden hasLogo={false} />
      <ErrorMessage>
        <h1>420 Error</h1>
      </ErrorMessage>
    </Page>
  );
};

export default NotFoundPage;
